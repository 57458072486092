import * as polished from 'polished';

export const breakpoints = {
  xs: 440,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
};

export const theme = {
  fonts: {
    mainFont: `'Helvetica Neue', sans-serif`,
    specialFont: `'Angelique', sans-serif`,
  },
  colors: {
    white: '#ffffff',
    dark: '#000000',
    gray: '#7D7D7D',
    gray100: '#707070',
    gray200: '#EEEEEE',
    gray300: '#BFBFBF',
    darkGreen: '#2b3423',
    gold: '#c9c2a8',
    gold100: '#b8b29a',
    orange: '#ff9000',
    orange100: '#ffbb66',
  },
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  fontSize: {
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.9rem',
    lg: '2.2rem',
    lgm: '2.6rem',
    xl: '3rem',
    xlg: '3.6rem',
    xxl: '6rem',
    xxxl: '7.2rem',
  },
  navSize: {
    desktop: '100px',
    mobile: '75px',
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
  ...polished,
};
